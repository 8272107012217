<script lang="tsx">
import type { PropType, SlotsType, VNode } from 'vue'
import type { CheckboxValue } from '@core/app/composables/components'
import type { BaseUiCheckboxProps } from '../BaseUiCheckbox.vue'
import type { BaseFormElementProps } from '@core-types/components'
import type { BaseUiFormGroupProps, BaseUiFormGroupSlots } from './BaseUiFormGroup.vue'
import { BaseUiCheckbox, BaseUiFormGroup } from '#components'
import type { FormFieldObject } from '@core-types/form'

export type BaseUiFormCheckboxProps = BaseUiCheckboxProps & Omit<
    BaseUiFormGroupProps, 'noLabelElement'
> & Omit<
    BaseFormElementProps<CheckboxValue>, 'descriptionId'
> & {
    labelClass?: string
    inputClass?: string
}

type BaseUiFormCheckboxSlots<T> = Omit<BaseUiFormGroupSlots, 'default'> & {
    'default': {}
    'below-label': {}
}

type ComponentOptions = {}

export function defineComponentBaseUiFormCheckbox<T>(options?: ComponentOverrideOptions<ComponentOptions, BaseUiFormCheckboxProps, BaseUiFormCheckboxSlots<T>>) {
    return defineComponent(
        (props: BaseUiFormCheckboxProps, ctx) => {

            return () => (
                <BaseUiFormGroup
                    form={props.form}
                    help={props.help}
                    descriptionAbove={props.descriptionAbove}
                    descriptionBelow={props.descriptionBelow}
                    hideRequired={props.hideRequired}
                    label={props.label}
                    noLabelElement={undefined}
                    errorId={props.errorId}
                    descriptionId={props.descriptionId}
                    disabled={props.disabled}
                    loading={props.loading}
                    required={props.required}
                    id={props.id}
                    ariaLabel={props.ariaLabel}
                    ariaInvalid={props.ariaInvalid}
                >
                    {(slotData: BaseUiFormGroupSlots['default']) => [

                        slotData.renderAboveDescription(),

                        (
                            <div class="sim-form-checkbox__container">
                                <BaseUiCheckbox
                                    modelValue={props.modelValue}
                                    onUpdate:modelValue={(val: CheckboxValue) => ctx.emit('update:modelValue', val)}
                                    form={props.form}
                                    onUpdate:form={(val: FormFieldObject<CheckboxValue>) => ctx.emit('update:form', val)}

                                    class={props.inputClass}
                                    type={props.type}

                                    id={slotData.inputId}
                                    value={props.value}
                                    name={props.name}
                                    clearable={props.clearable}
                                    disabled={props.disabled}
                                    loading={props.loading}
                                    required={slotData.isInputRequired}
                                    descriptionId={slotData.inputDescriptionIds}
                                    ariaLabel={props.ariaLabel}
                                    ariaInvalid={slotData.isInputAriaInvalid}

                                    onChange={(value: CheckboxValue) => ctx.emit('change', value)}
                                    onChecked={() => ctx.emit('checked')}
                                    onUnchecked={() => ctx.emit('unchecked')}
                                />

                                {
                                    slotData.renderLabel(
                                        (
                                            renderSlot(ctx.slots.default, options?.slots?.default, {}, (
                                                <>
                                                    {props.label}
                                                </>
                                            ))
                                        ) as VNode,
                                        {
                                            textNormal: true,
                                            hasDefaultSlotContent: ctx.slots.default !== undefined || !!options?.slots?.default,
                                            class: ['cursor-pointer', props.labelClass].filter(Boolean).join(' '),
                                            slotBelow: renderSlot(ctx.slots['below-label'], options?.slots?.['below-label'], {}) as VNode,
                                        }
                                    )
                                }

                            </div>
                        ),

                        slotData.renderBelowDescriptionAndError(),

                    ]}
                </BaseUiFormGroup>
            )
        },
        {
            props: {
                modelValue: {
                    type: [Boolean, String, Number, Array] as PropType<BaseUiFormCheckboxProps['modelValue']>,
                    default: options?.props?.modelValue?.default,
                    required: options?.props?.modelValue?.required ?? false,
                },
                form: {
                    type: Object as PropType<BaseUiFormCheckboxProps['form']>,
                    default: options?.props?.form?.default,
                    required: options?.props?.form?.required ?? false,
                },
                disabled: {
                    type: Boolean as PropType<BaseUiFormCheckboxProps['disabled']>,
                    default: options?.props?.disabled?.default,
                    required: options?.props?.disabled?.required ?? false,
                },
                loading: {
                    type: Boolean as PropType<BaseUiFormCheckboxProps['loading']>,
                    default: options?.props?.loading?.default,
                    required: options?.props?.loading?.required ?? false,
                },
                required: {
                    type: Boolean as PropType<BaseUiFormCheckboxProps['required']>,
                    default: options?.props?.required?.default,
                    required: options?.props?.required?.required ?? false,
                },
                ariaLabel: {
                    type: String as PropType<BaseUiFormCheckboxProps['ariaLabel']>,
                    default: options?.props?.ariaLabel?.default,
                    required: options?.props?.ariaLabel?.required ?? false,
                },
                ariaInvalid: {
                    type: Boolean as PropType<BaseUiFormCheckboxProps['ariaInvalid']>,
                    default: options?.props?.ariaInvalid?.default,
                    required: options?.props?.ariaInvalid?.required ?? false,
                },

                help: {
                    type: String as PropType<BaseUiFormCheckboxProps['help']>,
                    default: options?.props?.help?.default,
                    required: options?.props?.help?.required ?? false,
                },
                descriptionAbove: {
                    type: String as PropType<BaseUiFormCheckboxProps['descriptionAbove']>,
                    default: options?.props?.descriptionAbove?.default,
                    required: options?.props?.descriptionAbove?.required ?? false,
                },
                descriptionBelow: {
                    type: String as PropType<BaseUiFormCheckboxProps['descriptionBelow']>,
                    default: options?.props?.descriptionBelow?.default,
                    required: options?.props?.descriptionBelow?.required ?? false,
                },
                hideRequired: {
                    type: Boolean as PropType<BaseUiFormCheckboxProps['hideRequired']>,
                    default: options?.props?.hideRequired?.default,
                    required: options?.props?.hideRequired?.required ?? false,
                },
                label: {
                    type: String as PropType<BaseUiFormCheckboxProps['label']>,
                    default: options?.props?.label?.default,
                    required: options?.props?.label?.required ?? false,
                },
                errorId: {
                    type: String as PropType<BaseUiFormCheckboxProps['errorId']>,
                    default: options?.props?.errorId?.default,
                    required: options?.props?.errorId?.required ?? false,
                },
                descriptionId: {
                    type: [String, Array] as PropType<BaseUiFormCheckboxProps['descriptionId']>,
                    default: options?.props?.descriptionId?.default,
                    required: options?.props?.descriptionId?.required ?? false,
                },

                type: {
                    type: String as PropType<BaseUiFormCheckboxProps['type']>,
                    default: options?.props?.type?.default ?? 'checkbox',
                    required: options?.props?.type?.required ?? false,
                },
                value: {
                    type: [String, Number, Boolean] as PropType<BaseUiFormCheckboxProps['value']>,
                    default: options?.props?.value?.default,
                    required: options?.props?.value?.required ?? false,
                },
                clearable: {
                    type: Boolean as PropType<BaseUiFormCheckboxProps['clearable']>,
                    default: options?.props?.clearable?.default,
                    required: options?.props?.clearable?.required ?? false,
                },

                labelClass: {
                    type: String as PropType<BaseUiFormCheckboxProps['labelClass']>,
                    default: options?.props?.labelClass?.default,
                    required: options?.props?.labelClass?.required ?? false,
                },
                inputClass: {
                    type: String as PropType<BaseUiFormCheckboxProps['inputClass']>,
                    default: options?.props?.inputClass?.default,
                    required: options?.props?.inputClass?.required ?? false,
                },
            },
            slots: Object as SlotsType<BaseUiFormCheckboxSlots<T>>,
            emits: {
                'update:modelValue': (val: CheckboxValue) => true,
                'update:form': (val: FormFieldObject<CheckboxValue>) => true,
                'checked': () => true,
                'unchecked': () => true,
                'change': (value: CheckboxValue) => true,
            },
        }
    )
}

export default defineComponentBaseUiFormCheckbox()

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiFormCheckbox.scss" as *;

</style>
