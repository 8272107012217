<script lang="tsx">
import { defineComponentBaseUiCheckbox } from '@core/app/components/base/ui/BaseUiCheckbox.vue'

export default defineComponentBaseUiCheckbox({
    slots: {
        checkbox: ({ isChecked }) => isChecked
            ? <svg height="8" fill="currentColor"
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 9"
            >
                <path
                    d="M10.6608 2.45224c.4523-.44633.4523-1.17116 0-1.617493-.4522-.446329-1.18662-.446329-1.63885 0L4.05468 5.74079 1.97805 3.69125c-.45223-.44633-1.186651-.44633-1.638879 0-.452228.44633-.452228 1.17117 0 1.6175l2.894259 2.8565c.45223.44633 1.18665.44633 1.63888 0l5.78849-5.71301Z"
                />
            </svg>
            : null,
        radio: ({ isChecked }) => isChecked
            ?
            <svg width="14" height="14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="7" cy="7" r="7" fill="currentColor" />
            </svg>
            : null,
    },
})

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiCheckbox" as *;

@include checkbox {
    $outline-size: 2px;
    $size: calc(1.25em - (#{$outline-size} * 2)); // 20px with outline
    width: $size;
    height: $size;

    border: $outline-size solid $sim-c-primary-800;
    border-radius: 0.2em;
    background-color: white;
    color: white;
}

@include checkbox--checked {
    background-color: $sim-c-simplo;
    border-color: $sim-c-simplo;
}

@include radio {
    $outline-size: 2px;
    $size: calc(1.5em - 2 * #{$outline-size}); // 24px with outline
    width: $size;
    height: $size;

    border: $outline-size solid $sim-c-primary-800;
    border-radius: 100vw;
    background-color: white;
    color: $sim-c-simplo;
}

</style>
